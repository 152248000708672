<template>
    <section class="fade">
        <v-container class="container-main" ref="homeSection">
            <v-app-bar  flat color="white" class="psgs-title ">
                <div  class="center-content">
                    <v-img src="../../assets/images/logo.png" alt="Logo" contain height="90px" width="90px" />
                </div>
                <!-- NOTE Navigation Links -->
                <v-spacer></v-spacer> <!-- Pushes content to the right -->
                <div>
                    <v-tabs align-tabs="center" v-model="tab" color="primary">
                        <v-tab @click="scrollToSection(0)" :value="0">Home</v-tab>
                        <v-tab @click="scrollToSection(1)" :value="1">About MIS HOL</v-tab>
                        <v-tab @click="scrollToSection(2)" :value="2">MIS HOL Steps</v-tab>
                        <v-tab @click="scrollToSection(3)" :value="2">FAQs</v-tab>
                    </v-tabs>
                </div>
                <!-- NOTE Create Account Button -->
                <v-spacer></v-spacer> <!-- Optional: Adds space between links and button -->
                <v-btn disabled outlined color="primary" href="#">
                    <router-link to="/signup" class="text-decoration-none">
                    <span class="primary--text">Create Account</span> 
                    </router-link>
                </v-btn>
            </v-app-bar>
            <!-- NOTE Main Content -->
            <v-main class="maintext">
            <!--  NOTE Title Section -->
                <h1 class="heading">Philippine Society of General Surgeons Minimally Invasive Surgery</h1>
                <p class="text-center" >Learn your way, anytime, anywhere – with the perfect blend of online and in-person learning!</p>
                <div class="text-center">
                    <v-btn class="sign-in-button" color="primary" @click="login()">Sign In</v-btn>
                </div>
                <!--  End of Title Section -->

                <v-img style="margin-top: 100px;" class="text-center" src="../../assets/images/newlanding.png"/>
                <!--  End of Main Content -->

                <!--  NOTE Sub Section -->
                <div class="text-center" style="margin-top: 200px;">
                    <h1 class="heading1">
                        What is <span style="color: navy;">Minimally Invasive Surgery</span> Hybrid Online Learning?
                    </h1>
                    <p style="margin-top: 70px;">
                        Since the introduction of minimally invasive surgery (MIS) in the early 1990s, 
                        it has gained widespread acceptance because of the improved outcomes, 
                        such as faster recovery, shorter hospitalization, and better quality of life, 
                        that resulted from the procedure.
                    </p>
                    <p>
                        The Philippine Society of General Surgeons (PSGS) has incorporated MIS into its 
                        program focusing on curriculum improvement by adopting this world-class training 
                        tool supplementing it with hands-on experience to develop surgeons who are well 
                        versed in MIS through its Hybrid Online Learning (HOL), ensuring their proficiency 
                        through our rigorous assessment system grounded on international standards led by 
                        World College Of Laparoscopic Surgeons (WCLS), recognizing that we seek to contribute 
                        to this evolving practice as well; hence focus on MIS workshop. This approach results 
                        in more streamlined and efficient learning processes.
                    </p>
                </div>
                <!--  End of Sub Section -->
                <!--  NOTE About MIS HOL -->
                <div ref="aboutSection" class="text-center" style="margin-top: 200px;">
                    <h1 class="heading2">
                        About MIS HOL
                    </h1>
                </div>
                <div style="margin-top: 50px;" class="psgs-title">
                    <v-row justify="center">
                        <v-col cols="4">
                            <v-container>
                                <v-card class="card-align" height="400"> <!-- Set a fixed height for all cards -->
                                    <v-container>
                                        <v-img src="../../assets/images/Frame.png" alt="Logo" contain height="45px" width="45px" ></v-img>
                                    </v-container>
                                    
                                    <v-card-title class="headline">Complete Online Activity BEFORE the FTF Workshop</v-card-title>
                                    <v-list>
                                        <v-list-item >
                                        <v-list-item-action>
                                            <div class="round">
                                                <input type="checkbox" checked id="checkbox" disabled/>
                                                <label for="checkbox"></label>
                                            </div>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                                <v-list-item-action-text> 
                                                <p style="margin-bottom: 0; font-size: 18px">
                                                    Pretest
                                                </p>
                                                </v-list-item-action-text>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-action>
                                            <div class="round">
                                                <input type="checkbox" checked id="checkbox" />
                                                <label for="checkbox"></label>
                                            </div>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                                <v-list-item-action-text> 
                                                <p style="margin-bottom: 0; font-size: 18px">
                                                    Lecture Videos
                                                </p>
                                                </v-list-item-action-text>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-action>
                                            <div class="round">
                                                <input  type="checkbox" checked id="checkbox" />
                                                <label for="checkbox"></label>
                                            </div>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                                <v-list-item-action-text> 
                                                <p style="margin-bottom: 0; font-size: 18px">
                                                    Interim Test
                                                </p>
                                                </v-list-item-action-text>
                                        </v-list-item-content>
                                    </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-container>
                        </v-col>
                        <v-col cols="4">
                            <v-container>
                                <v-card class="card-align" height="400"> <!-- Set a fixed height for all cards -->
                                    <v-container>
                                        <v-img src="../../assets/images/Frame1.png" alt="Logo" contain height="45px" width="45px" ></v-img>
                                    </v-container>
                                    
                                    <v-card-title class="headline">Attend Live, Virtual FTF Workshop</v-card-title>
                                    <v-list>
                                    <v-list-item>
                                        <v-list-item-action>
                                            <div class="round">
                                                <input type="checkbox" checked id="checkbox" disabled/>
                                                <label for="checkbox"></label>
                                            </div>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                        <v-list-item-action-text>
                                            <p style="margin-bottom: 0; font-size: 18px">
                                                Ask-the-Experts Session
                                            </p>
                                        </v-list-item-action-text>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-action>
                                            <div class="round">
                                                <input type="checkbox" checked id="checkbox" />
                                                <label for="checkbox"></label>
                                            </div>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                        <v-list-item-action-text>
                                            <p style="margin-bottom: 0; font-size: 18px">
                                                Hands-on Session for drills with laparoscopic instruments
                                            </p>
                                        </v-list-item-action-text>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-action>
                                            <div class="round">
                                                <input  type="checkbox" checked id="checkbox" />
                                                <label for="checkbox"></label>
                                            </div>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                        <v-list-item-action-text>
                                            <p style="margin-bottom: 0; font-size: 18px">
                                                Open Forum with the faculty
                                            </p>
                                        </v-list-item-action-text>
                                        </v-list-item-content>
                                    </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-container>
                        </v-col>
                        <v-col cols="4">
                            <v-container>
                                <v-card class="card-align" height="400"> <!-- Set a fixed height for all cards -->
                                    <v-container>
                                        <v-img src="../../assets/images/Frame2.png" alt="Logo" contain height="45px" width="45px" ></v-img>
                                    </v-container>
                                    
                                    <v-card-title class="headline">Complete Online Activity AFTER the FTF Workshop</v-card-title>
                                    <v-list>
                                    <v-list-item>
                                        <v-list-item-action>
                                            <div class="round">
                                                <input type="checkbox" checked id="checkbox" disabled/>
                                                <label for="checkbox"></label>
                                            </div>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-action-text>
                                            <p style="margin-bottom: 0; font-size: 18px">
                                                Posttest
                                            </p>
                                        </v-list-item-action-text>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-action>
                                            <div class="round">
                                                <input type="checkbox" checked id="checkbox" />
                                                <label for="checkbox"></label>
                                            </div>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-action-text>
                                            <p style="margin-bottom: 0; font-size: 18px">
                                                Evaluation
                                            </p>
                                        </v-list-item-action-text>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-action>
                                            <div class="round">
                                                <input  type="checkbox" checked id="checkbox" />
                                                <label for="checkbox"></label>
                                            </div>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-action-text>
                                            <p style="margin-bottom: 0; font-size: 18px">
                                                Certification
                                            </p>
                                        </v-list-item-action-text>
                                        </v-list-item-content>
                                    </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-container>
                        </v-col>
                    </v-row>
                </div>
            </v-main>
        </v-container>
        <!--  End of About MIS HOL -->

        <template>
    <div ref="courseSection" style="margin-top: 200px;">
        <!-- Background Image Section -->
    <div class="background-section_junior">
      <v-container>
        <!-- Title Section -->
        <v-row justify="center" class="title-section">
          <v-col cols="12" class="text-center text-white mb-5">
            <h1 class="heading2">Junior Level Course</h1>
            <p>Participants: New Enrollees, Eligible, and Retakers (1st year to 5th year residents ONLY)</p>
          </v-col>
        </v-row>

        <!-- Steps Section -->
        <v-row justify="center" class="steps-section">
          <!-- Step 1 -->
          <v-col cols="12" md="3" class="mb-4">
            <v-card class="step-card">
              <v-card-title class="card-title">
                <h3>Step 1</h3>
              </v-card-title>
              <v-card-text class="card-text">
                <h4>JUNIOR MODULE</h4>
                <p>All residents must complete the modules before proceeding to the Junior Online SGD.</p>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Step 2 -->
          <v-col cols="12" md="3" class="mb-4">
            <v-card class="step-card">
              <v-card-title class="card-title">
                <h3>Step 2</h3>
              </v-card-title>
              <v-card-text class="card-text">
                <h4>JUNIOR ONLINE SGD</h4>
                <p><strong>Reminders:</strong></p>
                <ul>
                  <li>Completion of Junior Module</li>
                </ul>
                <p>Only the residents who completed the Junior Online Module are eligible to take Online SGD.</p>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Step 3 -->
          <v-col cols="12" md="3" class="mb-4">
            <v-card class="step-card">
              <v-card-title class="card-title">
                <h3>Step 3</h3>
              </v-card-title>
              <v-card-text class="card-text">
                <h4>JUNIOR ONLINE POST-TEST</h4>
                <p><strong>Reminders:</strong></p>
                <ul>
                  <li>Completion of Junior Online Post-test</li>
                </ul>
                <p>Only the residents who attended the SGD are eligible to go through the Online Post Test.</p>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Step 4 -->
          <v-col cols="12" md="3" class="mb-4">
            <v-card class="step-card">
              <v-card-title class="card-title">
                <h3>Step 4</h3>
              </v-card-title>
              <v-card-text class="card-text">
                <h4>JUNIOR CERTIFICATION</h4>
                <p><strong>Reminders:</strong></p>
                <ul>
                  <li>Completion of Junior Module, Junior SGD, and Junior Post-test</li>
                </ul>
                <p>
                  Certificates will be automatically generated once all steps for Junior Level are completed.
                </p>
                <p class="navy-blue"><strong>DON'T FORGET TO DOWNLOAD YOUR CERTIFICATES.</strong></p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

        <!-- Intermediate Level -->
        <div class="background-section_intermediate">
            <v-container>
                <!-- Title Section -->
                <v-row justify="center" class="title-section">
                    <v-col cols="12" class="text-center text-white mb-5">
                        <h1 class="heading2">Intermediate Level Course</h1>
                        <p>Participants: New Enrollees, Eligible, and Retakers (2nd year to 5th year residents ONLY)</p>
                    </v-col>
                </v-row>

                <!-- Steps Section -->
                <v-row justify="center" class="steps-section">
                    <!-- Step 5 -->
                    <v-col cols="12" md="3" class="mb-4">
                        <v-card class="step-card">
                            <v-card-title class="card-title">
                                <h3>Step 5</h3>
                            </v-card-title>
                            <v-card-text class="card-text">
                                <h4>INTERMEDIATE ONLINE MODULE</h4>
                                <p><strong>Reminders:</strong></p>
                                <ul>
                                    <li>Completion of Junior Module</li>
                                </ul>
                                <p>All residents must complete the online modules before proceeding to the Intermediate Face to Face Workshop.</p>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <!-- Step 6 -->
                    <v-col cols="12" md="3" class="mb-4">
                        <v-card class="step-card">
                            <v-card-title class="card-title">
                                <h3>Step 6</h3>
                            </v-card-title>
                            <v-card-text class="card-text">
                                <h4>INTERMEDIATE FACE TO FACE WORKSHOP</h4>
                                <p><strong>Reminders:</strong></p>
                                <ul>
                                    <li>Completion of Intermediate Module</li>
                                </ul>
                                <p>Only the residents who completed the Intermediate Online Module are eligible to take the Online SGD.</p>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <!-- Step 7 -->
                    <v-col cols="12" md="3" class="mb-4">
                        <v-card class="step-card">
                            <v-card-title class="card-title">
                                <h3>Step 7</h3>
                            </v-card-title>
                            <v-card-text class="card-text">
                                <h4>INTERMEDIATE ONLINE POST-TEST</h4>
                                <p><strong>Reminders:</strong></p>
                                <ul>
                                    <li>Completion of Intermediate Online Post-test</li>
                                </ul>
                                <p>Only the residents who attended the SGD are eligible to go through the Online Post Test.</p>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <!-- Step 8 -->
                    <v-col cols="12" md="3" class="mb-4">
                        <v-card class="step-card">
                            <v-card-title class="card-title">
                                <h3>Step 8</h3>
                            </v-card-title>
                            <v-card-text class="card-text">
                                <h4>INTERMEDIATE CERTIFICATION</h4>
                                <p><strong>Reminders:</strong></p>
                                <ul>
                                    <li>Completion of Intermediate Module, Face to Face Workshop, and Intermediate Post-test</li>
                                </ul>
                                <p>Certificates will be automatically generated once all steps for Intermediate Level are completed.</p>
                                <p class="navy-blue"><strong>DON'T FORGET TO DOWNLOAD YOUR CERTIFICATES.</strong></p>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <!-- Senior Level -->
        <div class="background-section_senior">
            <v-container>
                <!-- Title Section -->
                <v-row justify="center" class="title-section">
                    <v-col cols="12" class="text-center text-white mb-5">
                        <h1 class="heading2">Senior Level Course</h1>
                        <p>Participants: New Enrollees, Eligible, and Retakers (4th year to 5th year residents ONLY)</p>
                    </v-col>
                </v-row>

                <!-- Steps Section -->
                <v-row justify="center" class="steps-section">
                    <!-- Step 9 -->
                    <v-col cols="12" md="3" class="mb-4">
                        <v-card class="step-card">
                            <v-card-title class="card-title">
                                <h3>Step 9</h3>
                            </v-card-title>
                            <v-card-text class="card-text">
                                <h4>SENIOR ONLINE MODULE</h4>
                                <p><strong>Reminders:</strong></p>
                                <ul>
                                    <li>Completion of Intermediate Module</li>
                                </ul>
                                <p>All residents must complete the online modules before proceeding to the Senior Online SGD.</p>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <!-- Step 10 -->
                    <v-col cols="12" md="3" class="mb-4">
                        <v-card class="step-card">
                            <v-card-title class="card-title">
                                <h3>Step 10</h3>
                            </v-card-title>
                            <v-card-text class="card-text">
                                <h4>SENIOR ONLINE SGD</h4>
                                <p><strong>Reminders:</strong></p>
                                <ul>
                                    <li>Completion of Intermediate Module</li>
                                </ul>
                                <p>Only the residents who completed the Senior Online Module are eligible to take the Online SGD.</p>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <!-- Step 11 -->
                    <v-col cols="12" md="3" class="mb-4">
                        <v-card class="step-card">
                            <v-card-title class="card-title">
                                <h3>Step 11</h3>
                            </v-card-title>
                            <v-card-text class="card-text">
                                <h4>SENIOR ONLINE POST-TEST</h4>
                                <p><strong>Reminders:</strong></p>
                                <ul>
                                    <li>Completion of Senior Online Post-test</li>
                                </ul>
                                <p>Only the residents who attended the SGD are eligible to go through the Online Post Test.</p>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <!-- Step 12 -->
                    <v-col cols="12" md="3" class="mb-4">
                        <v-card class="step-card">
                            <v-card-title class="card-title">
                                <h3>Step 12</h3>
                            </v-card-title>
                            <v-card-text class="card-text">
                                <h4>SENIOR CERTIFICATION</h4>
                                <p><strong>Reminders:</strong></p>
                                <ul>
                                    <li>Completion of Senior Module, Senior SGD, and Senior Post-test</li>
                                </ul>
                                <p>Certificates will be automatically generated once all steps for Senior Level are completed.</p>
                                <p class="navy-blue"><strong>DON'T FORGET TO DOWNLOAD YOUR CERTIFICATES.</strong></p>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <!-- NOTE start FAQs Section -->
        <v-container>
            <div ref="faqsSection" class="text-center" style="margin-top: 200px;">
                <h1 class="heading2">
                    Frequently Asked Questions (FAQs)
                </h1>
            </div>
            <v-expansion-panels v-model="expandedPanel">
                <v-expansion-panel >
                    <v-expansion-panel-header>Getting Started</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p><strong>1. How do I log in?</strong><br>
                            <ul>
                                <li>To log in, visit the LMS platform login page and enter your username (your registered email address) and password. If it's your first time logging in, use the temporary password provided to you. You will be prompted to change your password upon your first login.</li>
                            </ul>
                        </p>

                        <p><strong>2. What should I do if I can’t log in?</strong><br>
                            <ul>
                                <li>If you are unable to log in, first ensure that you are using the correct username and password. If you still can’t log in, click on the "Forgot Password" link on the login page to reset your password. If the issue persists, contact our support team at info@pcitech.com.ph.</li>
                            </ul>
                        </p>

                        <p><strong>3. How do I reset my password?</strong><br>
                            <ul>
                                <li>To reset your password, click on the "Forgot Password" link on the login page. Enter your registered email address, and you will receive an email with instructions on how to reset your password.</li>
                            </ul>
                        </p>

                        <p><strong>4. How do I update my profile information?</strong><br>
                            <ul>
                                <li>To update your profile information, log in to the LMS platform and go to the "Profile" section from the navigation menu. Here, you can edit your personal details, change your password, and update your contact information.</li>
                            </ul>
                        </p>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>Course Enrollment and Access</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p><strong>1. How do I enroll in a course?</strong><br><br>
                        <strong>A. As a New Enrollee</strong> <br>
                        <ul>
                                <li>As a new enrollee, you will be automatically enrolled in our LMS, starting with the Junior Module. Once the course is open, it will appear as "Open" on the Home page, and you can continue it in "My Course".</li>
                            </ul>
                        </p>

                        <p><strong>B. Eligible</strong><br>
                            <ul>
                                <li>Please note your year level: if you are a 1st to 5th year student, you can take the Junior Level Course. If you are a 2nd to 5th year student, you will be automatically enrolled in this course. For 4th to 5th year students, once you complete the Junior and Intermediate course, you will be automatically enrolled in the Senior Module, which you can find in "My Course".</li>
                            </ul>
                            </p>

                        <p><strong>C. Retaker</strong><br>
                            <ul>
                                <li>For retakers, you need to pay before enrolling in the course to gain access. Once the payment is cleared, you will be automatically enrolled in the specific course where you left off.</li>
                            </ul>
                            </p>

                            <p><strong>2. What should I do if I have no access to certain courses?</strong><br>
                                <ul>
                                    <li>If you find that you do not have access to certain courses or materials, ensure that you are enrolled in the appropriate course. Additionally, make sure you have completed the prerequisite course with 100% progress, otherwise, you will not be enrolled in the next course.</li>
                                    <li>If the issue remains, please contact your course administrator or our support team at info@pcitech.com.ph for assistance.</li>
                                </ul>
                            </p>

                        <p><strong>3. Do you see yourself using this type of platform in class?</strong><br>
                            <ul>
                                <li>We encourage you to consider how the LMS platform can be integrated into your teaching methods. Your feedback on usability and applicability in classroom settings is invaluable to us.</li>
                            </ul>
                            </p>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>Navigation and Usage</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p><strong>1. How do I navigate the LMS platform? </strong><br>
                            <ul>
                                <li>After logging in, you will be taken to the dashboard. Here, you can access your courses, view announcements, check your progress, and manage your profile. Use the navigation menu on the left side to explore different sections of the platform.</li>
                            </ul>
                            </p>

                        <p><strong>2. How can I track my course progress?</strong><br>
                            <ul>
                                <li>You can track your course progress by visiting the "My Courses" section. Here, you'll see a progress bar for each course you are enrolled in, indicating the percentage of the course you have completed.</li>
                            </ul>
                            </p>

                        <p><strong>3. Is there a time limit to complete my courses?</strong><br>
                            <ul>
                                <li>Course completion times vary. Check the course details for specific deadlines. Some courses may have flexible schedules, while others may have strict timelines.</li>
                            </ul>
                            </p>

                        <p><strong>4. How do I participate in Small Group Discussion and Face to Face Workshop?</strong><br>
                            <ul>
                                <li>Live sessions or webinars can be accessed through the "Junior SGD", "Face to Face Workshop", and “Senior SGD” course. The links are available on the said courses.</li>
                            </ul>
                            </p>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>Dashboard Customization</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p><strong>1. Can I customize my dashboard or user interface?</strong><br>
                            <ul>
                                <li>Some LMS platforms offer dashboard customization features that allow you to prioritize specific courses. You can filter courses by categories such as Junior level, Intermediate level, or Senior level, making it easier to focus on the courses that are most relevant to you at any given time. Also you can change the format for the courses.</li>
                            </ul>
                            </p>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>Technical Support</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p><strong>1. What should I do if I encounter technical issues during a course?</strong><br>
                            <ul>
                                <li>If you encounter technical issues while taking a course, try refreshing the page or closing your browser cache. If the problem persists, contact our technical support team at info@pcitech.com.ph.</li>
                            </ul>
                        </p>

                        <p><strong>2. How do I contact support?</strong><br>
                            <ul>
                                <li>If you need assistance, you can contact our support team via email at info@pcitech.com.ph. Our support hours are 9:00 am to 5:00 pm.</li>
                            </ul>
                            </p>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>Post-Test/Assessments</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p><strong>1. What should I do if I miss a deadline?</strong><br>
                            <ul>
                                <li>If you miss a deadline, reach out to your course instructor as soon as possible to discuss your options. Depending on the course policy, you may be able to get an extension or make up the missed work.</li>
                            </ul>
                            </p>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>Certification and Completion</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p><strong>1. How do I get certified?</strong><br>
                            <ul>
                                <li>Certificates are awarded upon successful completion of a course. Once you have completed all required modules and passed the assessments, you will receive a notification, and your certificate will be available for download in the "Certificates" section of your profile.</li>
                            </ul>
                            </p>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <div style="height: 200px;"></div>
            
            <v-footer app padless style="background-color: #1d3e7a; color: white; text-align: center;">
                <v-col cols="12" class="d-flex justify-center">
                    <v-btn text class="white--text" @click="scrollToSection(0)" :value="0">Home</v-btn>
                    <v-btn text class="white--text" @click="scrollToSection(1)" :value="1">About MIS HOL</v-btn>
                    <v-btn text class="white--text" @click="scrollToSection(2)" :value="2">MIS HOL Steps</v-btn>
                    <v-btn text class="white--text" @click="scrollToSection(3)" :value="3">FAQs</v-btn>
                </v-col>
                <v-col cols="12" class="text-center">
                    <span>Copyright © PSGS 2023.</span>
                </v-col>
            </v-footer>
        </v-container>
        <!-- end of FAQs Section -->
    </div>
</template>

                
    </section>
</template>
    
<style scoped>

@media (max-width: 600px){
    .psgs-title {
        display: none;
    }
}

@media (min-width: 601px) {
    .psgs-title {
        display: block;
    }
}
</style>

<script>
import { mapActions, mapState } from "vuex";
import Login from "@/components/landing/forms/Login.vue";
import Register from "@/components/landing/forms/Register.vue";
import HomeCard from "@/components/landing/cards/home-card.vue";
import Section from '../admin/Section.vue';

export default {
    name: 'landing-home',
    components: {
        Login,
        Register,
        HomeCard,
        Section
    },
    data: () => ({
        showLogin: true,
        loading: false,
        tab: 0 ,
        expandedPanel: 0
    }),
    beforeMount() {
        // this.$router.push('/login')
    },
    mounted() {
        this.loading = true
        this.getHomepageCourses().then(() => {
            this.loading = false
        })
    },
    methods: {
        ...mapActions([
            'getHomepageCourses'
        ]),
        scrollToSection(tab) {
            switch (tab) {
                case 0:
                this.$refs.homeSection.scrollIntoView({ behavior: "smooth" });
                break;
                case 1:
                this.$refs.aboutSection.scrollIntoView({ behavior: "smooth" });
                break;
                case 2:
                this.$refs.courseSection.scrollIntoView({ behavior: "smooth" });
                break;
                case 3:
                this.$refs.faqsSection.scrollIntoView({ behavior: "smooth" });
                break;
                default:
                break;
            }
        },
        login(){
            this.$router.push('/login')
        }
    },
    computed: {
        ...mapState({
            courses: (state) => state.other_courses
        }),
    }
}
</script>